const messages_sv = {
    login: 'Logga in',
    products: 'Produkter',
    about: 'Om oss',
    contact_us: 'Kontakta oss',
    support: 'Kundservice',
    'contactform.accept_marketing':
        'Jag vill gärna få marknadsföringsmeddelanden och tips om hur jag använder tjänsten',
    'contactform.name': 'Namn',
    'contactform.phone': 'Telefon',
    'contactform.email': 'E-post',
    'contactform.message': 'Meddelande',
    'contactform.skynet_pot': 'Fyll inte detta om du är en människa:',
    'contactform.submit': 'Skicka',
    'contactform.error': 'Å nej! Det uppstod ett fel när formuläret skickades! Vänligen försök igen senare.',
    'contactform.thankyou.h': 'Tack!',
    'contactform.thankyou.p': 'Vi fick ditt meddelande och tar en titt på det snart.',
    'cookieconsent.heading': 'Information om cookies',
    'cookieconsent.content.p':
        'Vår webbplats använder cookies från första och tredje part för att kunna erbjuda den bästa möjliga användarupplevelse när du besöker oss. Vi använder oss av cookies för prestanda och analys endast ifall du accepterar dem genom att välja Acceptera. Genom att välja Avvisa nekar du användningen av cookies för prestanda och analys, men vi kan fortfarande använda nödvändiga förstapartscookies om du fortsätter att surfa på vår webbplats. <a>Här</a> finner du vår Cookie policy.',
    'cookieconsent.decline': 'Avvisa',
    'cookieconsent.accept': 'Acceptera',
    'footer.logo_punchline': 'Det är som en app, men bättre.',
    'footer.company': 'Bolag',
    'footer.company.careers': 'Karriärer',
    'footer.learn_more': 'Läs mer',
    'footer.learn_more.faq': 'FAQ',
    'footer.learn_more.terms_of_use': 'Villkor',
    'footer.learn_more.privacy_policy': 'Integritetspolicy',
    'footer.learn_more.cookie_policy': 'Cookiepolicy',
    'footer.merchant_support': 'Handelsstöd',
    'footer.developers': 'Mjukvaruutvecklare',
}

export default messages_sv
