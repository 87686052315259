const messages_en = {
    login: 'Login',
    products: 'Products',
    about: 'About us',
    contact_us: 'Contact us',
    support: 'Support',
    'contactform.accept_marketing': 'I wish to receive marketing messages and tips on how to use the service',
    'contactform.name': 'Name',
    'contactform.phone': 'Phone',
    'contactform.email': 'Email',
    'contactform.message': 'Message',
    'contactform.skynet_pot': 'Do not fill this out if you’re human:',
    'contactform.submit': 'Send',
    'contactform.error': 'Oh no, there was an error submitting this form. Please try again later!',
    'contactform.thankyou.h': 'Thank you!',
    'contactform.thankyou.p': 'We received your message and will take a look at it soon.',
    'cookieconsent.heading': 'You control your data',
    'cookieconsent.content.p':
        'Our website uses first and third party cookies that help the website function, so that we can provide you with the best possible user experience. We will only use analytical cookies if you consent to it by clicking on Accept. By clicking Reject you deny the use of analytical cookies, but we may still use necessary first party cookies if you continue browsing our website. Our Cookie policy is found <a>here</a>.',
    'cookieconsent.decline': 'Reject',
    'cookieconsent.accept': 'Accept',
    'footer.logo_punchline': "It's like an app, but better.",
    'footer.company': 'Company',
    'footer.company.careers': 'Careers',
    'footer.learn_more': 'Learn more',
    'footer.learn_more.faq': 'FAQ',
    'footer.learn_more.terms_of_use': 'Terms of use',
    'footer.learn_more.privacy_policy': 'Privacy policy',
    'footer.learn_more.cookie_policy': 'Cookie policy',
    'footer.merchant_support': 'Merchant support',
    'footer.developers': 'Developers',
}

export default messages_en
